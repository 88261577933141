export default function () {

    return {

        contactUsFormMessage: '',
        contactUsFormLoading: false,
        contactUsButtonText: 'Send',

        contactUsData: {
            first_name: '',
            last_name: '',
            email: '',
            type: '',
            message: '',
            agree: '',
        },

        contactUsDataError: {
            first_name: '',
            last_name: '',
            email: '',
            type: '',
            message: '',
            agree: '',
        },

        async contactUs() {

            this.contactUsFormMessage = ''
            this.contactUsFormLoading = true
            this.contactUsButtonText = 'Sending...'

            await fetch('/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.contactUsData),
            })
            .then(response => response.json())
            .then((data) => {
                if(data.status === 'error' ) {
                    this.contactUsDataError.first_name = data.data.first_name
                    this.contactUsDataError.last_name = data.data.last_name
                    this.contactUsDataError.email = data.data.email
                    this.contactUsDataError.type = data.data.type
                    this.contactUsDataError.message = data.data.message
                    this.contactUsDataError.agree = data.data.agree
                }
                if(data.status === 'ok' ) {
                    this.contactUsFormMessage = 'Thank you for contacting Track Your Doctor. We will respond to your inquiry as soon as possible.'
                }
            })
            .finally(() => {
                this.contactUsFormLoading = false
                this.contactUsButtonText = 'Send'
            })
        },
    }
}
export default function (meilisearchConfig, index, searchOptions, prefix, zip, stateAbbr) {

    const defaultSearchOptions = {
        limit: 10,
        filter: 'entity = "physician"'
    }

    const citiesSearchOptions = {
        limit: 1,
        filter: ''
    }

    searchOptions = { ...defaultSearchOptions, ...searchOptions}

    var city = ''
    var state = ''

    if(zip) {
        citiesSearchOptions.filter = 'zip = "' + zip + '"'
        searchOptions.filter = searchOptions.filter + ' AND zip = "' + zip + '"'
    }

    if(stateAbbr) {
        citiesSearchOptions.filter = 'state_abbr = "' + stateAbbr + '"'
        searchOptions.filter = searchOptions.filter + ' AND state_abbr = "' + stateAbbr + '"'
    }

    var SEARCH_MUTEX = false

    var specid = ''
    var specname = ''

    return {
        location: "",
        avg_rating: "",
        specialties: "",
        gender: "",
        sort: "",
        city: city,
        state: state,
        locationIndex: null,
        locationResults: '',

        async filterLocation($event) {

            $event.preventDefault()

            city = $event.currentTarget.getAttribute('city')
            state = $event.currentTarget.getAttribute('state')
            zip = $event.currentTarget.getAttribute('zip')

            this.location = city + ' ' + state + ' ' + zip
            this.query = ''
        },

        async filterSpeciality($event) {

            $event.preventDefault()

            specid = $event.currentTarget.getAttribute('specid')
            specname = $event.currentTarget.getAttribute('specname')

            this.specialties = specname
            this.query = ''
        },

        $watchMany(fields) {
            fields.forEach((field) => {
                this.$watch(field, (val) => {
                    if (field === 'specialties') {
                        if (val === '') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?\(specialty_id IN \[(.*?)\] OR specialty_id_opt IN \[(.*?)\]\)/g, '')
                        }
                        else {
                            if(~searchOptions.filter.indexOf('specialty_id')) {
                                    searchOptions.filter = searchOptions.filter.replace(/\(specialty_id IN \[(.*?)\] OR specialty_id_opt IN \[(.*?)\]\)/g, '(specialty_id IN [' + specid + '] OR specialty_id_opt IN [' + specid + '])')
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + '(specialty_id IN [' + specid + '] OR specialty_id_opt IN [' + specid + '])'
                            }
                        }
                    }
                    if (field === 'avg_rating') {
                        if (val === '' || val === 'all') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?avg_rating >= \d/g, '')
                        }
                        else {
                            if(~searchOptions.filter.indexOf('avg_rating')) {
                                    searchOptions.filter = searchOptions.filter.replace(/avg_rating >= \d/g, 'avg_rating >= ' + val)
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + 'avg_rating >= ' + val
                            }
                        }
                    }
                    if (field === 'gender') {
                        if (val === '' || val === 'all') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?gender = "+([MFmf])+"/g, '')
                        }
                        else {
                            if(~searchOptions.filter.indexOf('gender')) {
                                searchOptions.filter = searchOptions.filter.replace(/gender = "+([MFmf])+"/g, 'gender = "' + val + '"')
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + 'gender = "' + val + '"'
                            }
                        }
                    }
                    if (field === 'sort') {
                        if (val === '' || val === 'bestmatches') {
                            searchOptions.sort = null
                        }
                        if (val === 'mostrated') {
                            searchOptions.sort = [
                                'total_reviews:desc'
                            ]
                        }
                    }

                    this.search(this.location)
                });
            });
        },

        async search (location) {
            this.locationResults = await this.locationIndex.search(this.location, searchOptions)
        },

        async searchCityState (cityState) {

            if(zip) {
                this.city = await this.citiesIndex.search(cityState, citiesSearchOptions)
            }

            if(stateAbbr) {
                this.state = await this.citiesIndex.search(cityState, citiesSearchOptions)
            }
        },

        init() {
            const client = new window.MeiliSearch(meilisearchConfig)

            this.locationIndex = client.index(index)

            this.citiesIndex = client.index(prefix + '-cities')

            if (SEARCH_MUTEX == false) {

                this.searchCityState('')

                this.search(this.location)
                this.$watchMany(['specialties', 'gender', 'avg_rating', 'sort' ])
                SEARCH_MUTEX = true
            }
        },
    };
}
import parsePhoneNumber from 'libphonenumber-js'

export default function (npi) {


    return {
        phone: '',
        phoneUrl: '',
        open: false,

        claimFormMessage: '',
        claimFormLoading: false,
        claimButtonText: 'Send a Request',

        data: {
            npi: '',
            business_name: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            address: '',
            city: {
                id: '',
                name: '',
                state: '',
                zip: '',
            },
            phone: '',
            about: '',
            specialties: [{
                id: '',
                name: '',
            }],
        },

        claimedData: {
            npi: '',
            business_name: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            address: '',
            city_id: '',
            phone: '',
            about: '',
            specialty_id: '',
        },

        businessName: '',

        claimedDataError: {
            npi: '',
            business_name: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            address: '',
            city_id: '',
            phone: '',
            about: '',
            specialty_id: '',
        },

        async claimData() {

            if(npi) {
                await fetch('/data/profile/' + npi, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                    },
                })
                .then(response => response.json())
                .then((data) => {
                    if(data.status = 'ok') {
                        this.data = {}
                        this.data = data.data;
        
                        //this.phone = parsePhoneNumber('+1' + this.data.phone)
        
                        //this.phone = this.phone.formatNational()

                        this.claimedData.npi = this.data.npi
                        this.claimedData.first_name = this.data.first_name
                        this.claimedData.last_name = this.data.last_name
                        this.claimedData.address = this.data.address
                        this.claimedData.city_id = this.data.city.id
                        this.claimedData.specialty_id = this.data.specialties[0].id
                    }
                })
            }
        },

        claimProfile(npi) {

            this.claimFormMessage = ''
            this.claimFormLoading = true
            this.claimButtonText = 'Requesting...'

            this.claimedData.phone = this.phone.replace(/[^0-9]/g,'')

            fetch('/claim-your-profile/' + npi, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.claimedData),
            })
            .then(response => response.json())
            .then((data) => {
                if(data.status === 'error' ) {
                    this.claimedDataError.business_name = data.data.business_name
                    this.claimedDataError.npi = data.data.npi
                    this.claimedDataError.first_name = data.data.first_name
                    this.claimedDataError.last_name = data.data.last_name
                    this.claimedDataError.email = data.data.email
                    this.claimedDataError.password = data.data.password
                    this.claimedDataError.password_confirmation = data.data.password_confirmation
                    this.claimedDataError.address = data.data.address
                    this.claimedDataError.city_id = data.data.city_id
                    this.claimedDataError.phone = data.data.phone
                    this.claimedDataError.about = data.data.about
                }
                if(data.status === 'ok' ) {

                    this.open = true

                }
            })
            .finally(() => {
                this.claimFormLoading = false;
                this.claimButtonText = 'Send a Request';
            })
        },

        getSuggestions(type) {

            fetch('/data/' + type, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
            })
            .then(response => response.json())
            .then((data) => {
                if(data.status = 'ok') {
                    this.suggestions = data.data
                }
            })

        },

        isEmail(email) {
            var re = /\S+@\S+\.\S+/
            return re.test(email)
        },

        isLenght(string) {
            var re = /^[a-zA-Z0-9  *.,]{3,}$/
            return re.test(string)
        },

        isFLName(string) {
            var re = /^[a-zA-Z]{2,}$/
            return re.test(string)
        },

        isNpi(number) {
            var re = /^[0-9]{10,10}$/
            return re.test(number)
        },

        isId(id) {
            var re = /^[0-9]{1,5}$/
            return re.test(id)
        },

        isPhone(phone) {
            var re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
            return re.test(phone)
        },

    }
}
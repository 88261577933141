export default function (meilisearchConfig, index, searchOptions) {
    const defaultSearchOptions = {
        limit: 10,
    }

    searchOptions = { ...defaultSearchOptions, ...searchOptions}

    var SEARCH_MUTEX = false

    return {
        query: "",
        index: null,
        results: '',
        skipWatch: false,

        watchQuery () {
            this.$watch("query", (query) => {
                if (query === '' || this.skipWatch == true) {
                    this.results = ''
                    return
                }
                this.search(query)
            });
        },

        async search (query) {
            this.results = await this.index.search(this.query, searchOptions)
        },
        
        init() {
            const client = new window.MeiliSearch(meilisearchConfig)

            this.index = client.index(index)

            if (SEARCH_MUTEX == false) {
                this.watchQuery()
                SEARCH_MUTEX = true
            }
        },
    };
}
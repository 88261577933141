var he = require('he')

export default function () {

    return {

        posts: [],

        async getPosts() {

            await fetch('https://blog.trackyourdoctor.com/wp-json/wp/v2/posts?_embed', {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            })
            .then(response => response.json())
            .then((data) => {

                this.posts = data
                this.posts.forEach((post) => {
                    post['excerpt'].rendered = post['excerpt'].rendered.replace(/(<([^>]+)>)/gi, '')
                    post['excerpt'].rendered = he.decode(post['excerpt'].rendered)
                    post['content'].rendered = he.decode(post['content'].rendered)
                    post['date'] = post['date'].split('T')[0]
                })

            })

        },

        relatedPosts: [],

        async getRelatedPosts(postId, categoryId) {

            await fetch('https://blog.trackyourdoctor.com/wp-json/wp/v2/posts?_embed&per_page=3&exclude=' + postId + '&categories=' + categoryId, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            })
            .then(response => response.json())
            .then((data) => {

                this.relatedPosts = data
                this.relatedPosts.forEach((post) => {
                    post['excerpt'].rendered = post['excerpt'].rendered.replace(/(<([^>]+)>)/gi, '')
                    post['excerpt'].rendered = he.decode(post['excerpt'].rendered)
                    post['content'].rendered = he.decode(post['content'].rendered)
                    post['date'] = post['date'].split('T')[0]
                })

            })

        },

    }
}
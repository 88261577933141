import jQuery from 'jquery';
window.$ = jQuery;

require('./bootstrap');

// Sidebar
$(document).ready(function () {

    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    });

    $('.menu-close').on('click', function () {
        $('#sidebar').toggleClass('active');
    });

    $('.mobile-overlay').on('click', function () {
        $('#sidebar').toggleClass('active');
    });
});

// Sidebar active class
$(function(){
    var current = location.pathname;
        $('#sidebar a').each(function(){
            var $this = $(this);
            if($this.attr('href').indexOf(current) !== -1) {
                if ( current != '/' ) {
                    $this.parent().addClass('active');
                }
                else {
                    $('#sidebar li:first-child').addClass('active');
                }
            }
        })

});


// Doctor's mini block
$(window).scroll(function() {
    if ($(this).scrollTop()<=600)
     {
        $('#profile-mini-block').hide();
     }
    else
     {
      $('#profile-mini-block').show();
     }
 });


 // Pricing sticky block
 $(window).scroll(function(){
    if ($("body").hasClass("pricing")) {
        var offset = $("#pricing-organization").offset();
        if ($(this).scrollTop() > offset.top - 100) {
            $('#compare-plans-physicians').removeClass('sticky-top');
        }
        else {
            $('#compare-plans-physicians').addClass('sticky-top');
        }
    }
});

// Profile navigation active class
$(document).ready(function () {
    $(document).on("scroll", onScroll);
    
    //smoothscroll
    $('.profile-navigation a[href^="#"]').on('click', function (e) {
        e.preventDefault();
        $(document).off("scroll");
        
        $('.nav-link').each(function () {
            $(this).removeClass('active');
        })
        $(this).addClass('active');
      
        var target = this.hash;

        $('html, body').stop().animate({
            'scrollTop': $(target).offset().top-150
        }, 500, 'swing', function () {
            //window.location.hash = target;
            $(document).on("scroll", onScroll);
        });
    });
});

function onScroll(event){
    var scrollPos = $(document).scrollTop();
    $('.profile-navigation .nav-link').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if ((refElement.position().top - 150) <= scrollPos && (refElement.position().top + refElement.height() - 150) > scrollPos) {
            $('#profile-navigation .nav-link').removeClass("active");
            currLink.addClass("active");
        }
        else{
            currLink.removeClass("active");
        }
    });
}


import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
 
window.Alpine = Alpine

Alpine.plugin(persist)

import { MeiliSearch } from 'meilisearch' 

window.MeiliSearch = MeiliSearch
 
import search from './components/search';
import locationSearch from './components/locationSearch';
import advancedSearch from './components/advancedSearch';
import profile from './components/profile';
import subscribe from './components/subscribe';
import spc from './components/spc';
import location from './components/location';
import claim from './components/claim';
import claimOrganization from './components/claimOrganization';
import contact from './components/contact';
import posts from './components/posts';
import share from './components/share';
import common from './components/common.js';

window.components = {
    search, 
    locationSearch,
    advancedSearch,
    profile,
    subscribe,
    spc,
    location,
    claim,
    claimOrganization,
    contact,
    posts,
    share,
    common,
};

Alpine.start()
import parsePhoneNumber from 'libphonenumber-js'

import Cookies from 'js-cookie'

export default function (meilisearchConfig, index, searchOptions, npi) {

    const defaultSearchOptions = {
        limit: 4,
        filter: 'entity = "physician"',
    }

    searchOptions = { ...defaultSearchOptions, ...searchOptions}

    npi = npi

    var SEARCH_MUTEX = false

    return {
        // Retrieve Data 
        request: {},
        data: {},
        phone: '',
        phoneUrl: '',
        reviews: [],
        location: "",
        index: null,
        results: '',

        profileId: null,
        offset: 0,
        lastPage: false,
        reviewsPerPage: 10,

        openLeaveReviewPopup: false,
        openSubscribePopup: false,

        query: '',
        sort: 'latest',
        rank: '',
        source: '',

        profileLoading: true,

        retrieveData() {


            this.profileId = this.data.id


            this.getReviews()

            searchOptions.filter = searchOptions.filter + ' AND (specialty_id = "' + this.data.specialties[0].id + '" OR specialty_id_opt = "' + this.data.specialties[0].id + '") AND state_abbr = "' + this.data.city.state_abbr + '"'
            
            if (SEARCH_MUTEX == false) {
                this.search(this.location)
                SEARCH_MUTEX = true
            }
        },

        getPhone(phone) {
            const phoneNumber = parsePhoneNumber(phone, 'US')
            return phoneNumber.getURI()
        },

        async like(e) {

            var reviewId = e.target.dataset.id
            var likes = e.target.dataset.likes

            let like = Cookies.get('liked' + reviewId )

            if (like !== 'true') {
                await fetch('/data/like/' + reviewId, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                    },
                })
                .then(response => response.json())
                .then((data) => {
                    if (data.status === 'ok') {

                        Cookies.set('liked'+ reviewId, true, { expires: 365, secure: true })

                        if(likes) {
                            likes = parseInt(likes) + 1
                        }
                        else {
                            likes = 1
                        }
                        e.target.outerHTML = '<p class="profile-like review-action mb-0"><span>' + likes + ' Likes</span></p>'
                        
                    }
                })
            }
        },

        getRandomInt(min, max) {
            min = Math.ceil(min)
            max = Math.floor(max)
            return Math.floor(Math.random() * (max - min) + min)
        },
        
        openExitPopup: false,

        exitPopup() {

            document.addEventListener('mouseout', e => {
                if (!e.toElement && !e.relatedTarget) {

                    if(this.checkCookie('exit') != true) {
                        this.openExitPopup = true
                        this.setCookie('exit', true)
                    }
                    
                }
            });

        },


        async getReviews() {

            var url = '/data/reviews/' + this.profileId + '?limit=' + this.reviewsPerPage + '&offset=' + this.offset

            if(this.query != '') {
                url = url + '&name=' + this.query
            }
            if(this.rank != '') {
                url = url + '&rating=' + this.rank
            }
            if(this.source != '') {
                url = url + '&source_id=' + this.source
            }
            if(this.sort != '') {
                url = url + '&order=' + this.sort
            }
            else {
                url = url
            }

            if(this.lastPage != true) {

                await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                    },
                })
                .then(response => response.json())
                .then((data) => {
                    if(data.status = 'ok') {

                        this.reviews = this.reviews.concat(data.data)

                        if (this.reviews.length === this.data.total_reviews || this.reviewsPerPage > data.data) {
                            this.lastPage = true
                            $('#load-more-button').remove()
                        }
                        else {
                            this.offset =  this.offset + this.reviewsPerPage
                        }

                        this.reviews.forEach((review) => {

                            review.iso_date = review.reviewed_at.substr(0, 10)
                        
                            review.reviewed_at = new Date(review.reviewed_at)
        
                            review.reviewed_at = review.reviewed_at.toLocaleDateString("en-US")
        
                        })
                    }
                })

            }

            if(this.reviewsPerPage >= this.reviews.total_reviews){
                this.lastPage = true
            }

            this.profileLoading = false
        },

        watchReviews() {

            this.$watch('query', (query) => {
                this.lastPage = false
                this.offset = 0
                this.reviews = []
                this.getReviews()
            })

            this.$watch('rank', (rank) => {
                this.lastPage = false
                this.offset = 0
                this.reviews = []
                this.getReviews()
            })

            this.$watch('source', (source) => {
                this.lastPage = false
                this.offset = 0
                this.reviews = []
                this.getReviews()
            })

            this.$watch('sort', (sort) => {
                this.lastPage = false
                this.offset = 0
                this.reviews = []
                this.getReviews()
            })

        },

        checkCookie(cname) {
            let like = Cookies.get(cname);
            if (like === 'true') {
                return true
            } else {
                return false
            }
        },

        setCookie(cname, cvalue) {
            Cookies.set(cname, cvalue, { expires: 1, secure: true })
        },

        subscribeLoggedIn(e) {

            var physicianId = e.target.dataset.id

            fetch('/patients/subscriptions/data/' + physicianId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
            })
            .then(response => response.json())
            .then((data) => {
                if(data.status = 'ok') {
                    this.openSubscribePopup = true
                }
            })

        },

        subscriberData: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
        },

        subscriberFormMessage: '',
        subscriberFormLoading: false,
        subscriberButtonText: 'Subscribe',
        subscriberErrorFirstName: '',
        subscriberErrorLastName: '',
        subscriberErrorEmail: '',
        subscriberErrorPassword: '',
        subscriberErrorPasswordConfirm: '',

        subscribeNotLoggedIn(e) {

            var physicianId = e.target.dataset.id

            this.subscriberFormMessage = '';
            this.subscriberFormLoading = true;
            this.subscriberButtonText = 'Subscribing...';

            fetch('/data/subscribe/'+ physicianId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.subscriberData),
            })
            .then(response => response.json())
            .then((data) => {
                if(data.status === 'error' ) {
                    this.subscriberErrorFirstName = data.data.first_name
                    this.subscriberErrorLastName = data.data.last_name
                    this.subscriberErrorEmail = data.data.email
                    this.subscriberErrorPassword = data.data.password
                    this.subscriberErrorPasswordConfirm = data.data.password_confirmation
                }
                if(data.status === 'ok' ) {
                    this.subscriberFormMessage = 'You have successfully left a review.'
                    this.step = 2;
                }
            })
            .finally(() => {
                this.subscriberFormLoading = false;
                this.subscriberButtonText = 'Subscribe';
            });

        },

        newReview: {
            id: '',
            source_name: 'Track Your Doctor',
            rating: '',
            likes: 0,
            name: '',
            url: '',
            text: '',
            reviewed_at: '',
        },

        reviewData: {
            rating: '',
            text: '',
            parent_id: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
        },

        reviewDataError: {
            rating: '',
            text: '',
            parent_id: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            physician_id: '',
        },

        formMessage: '',
        formLoading: false,
        buttonText: 'Leave Review',

        rating: 0,
        hoverRating: 0,
        ratings: [{'amount': 1, 'label':'Terrible'}, {'amount': 2, 'label':'Bad'}, {'amount': 3, 'label':'Okay'}, {'amount': 4, 'label':'Good'}, {'amount': 5, 'label':'Great'}],
        
        rate(amount) {
            this.rating = amount;
            this.reviewData.rating =  this.rating;
        },

        /*currentLabel() {
            let r = this.rating;
            if (this.hoverRating != this.rating) {r = this.hoverRating;}
            let i = this.ratings.findIndex(e => e.amount == r);
            if (i >=0) {return this.ratings[i].amount} else {return ''};     
        },*/

        submitReview(physicianId) {
            this.formMessage = '';
            this.formLoading = true;
            this.buttonText = 'Leaving Review...';
            fetch('/data/reviews/'+ physicianId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.reviewData),
            })
            .then(response => response.json())
            .then((data) => {

                if(data.status === 'error' ) {
                    this.reviewDataError.rating = data.data.rating
                    this.reviewDataError.text = data.data.text
                    this.reviewDataError.first_name = data.data.first_name
                    this.reviewDataError.last_name = data.data.last_name
                    this.reviewDataError.email = data.data.email
                    this.reviewDataError.password = data.data.password
                    this.reviewDataError.password_confirmation = data.data.password_confirmation
                    this.reviewDataError.physician_id = data.data.physician_id
                }
                if(data.status === 'ok' ) {
                    this.newReview.id = data.data
                    this.newReview.rating = this.reviewData.rating
                    this.newReview.text = this.reviewData.text
                    
                    if(this.reviewData.first_name && this.reviewData.last_name) {
                        this.newReview.name = this.reviewData.first_name + ' ' + this.reviewData.last_name
                    }

                    var today = new Date()

                    this.newReview.reviewed_at = (today.getMonth()+1) +'/'+ today.getDate() +'/'+ today.getFullYear();

                    this.reviews.unshift(this.newReview)

                    this.formMessage = 'You have successfully left a review.'
                    
                    this.step = 2
                }
            })
            .finally(() => {
                this.formLoading = false;
                this.buttonText = 'Leave Review';
            });
        },

        phyisicanReply: {
            text: ''
        },

        addReply(e) {

            var reviewId = e.target.dataset.id

            fetch('/physicians/reviews/replies/' + reviewId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.phyisicanReply),
            })

        },

        async search (query) {
            this.results = await this.index.search(query, searchOptions)
        },

        leaveReviewSSO() {

            var physicanRedirect = localStorage.getItem('_x_ssoLeaveReview')

            if(physicanRedirect != "null" && physicanRedirect) {
                this.openLeaveReviewPopup = true
            }

            localStorage.removeItem('_x_ssoLeaveReview')

        },

        ranking: [],
        sortedRanking: [],

        async topRated(specialtyId, stateAbbr) {

            searchOptions.limit = 100000

            searchOptions.sort = ['avg_rating:desc']

            searchOptions.filter = 'entity = "physician" AND (specialty_id = "' + specialtyId + '" OR specialty_id_opt = "' + specialtyId + '") AND state_abbr = "' + stateAbbr + '" AND avg_rating > 0'
                    
            this.ranking = await this.index.search('', searchOptions)

            this.ranking.hits.forEach((rank, index) => {
                this.ranking.hits[index].score = rank.avg_rating/5 * rank.total_reviews
            })

            this.ranking.hits.sort((a, b) => (a.score > b.score) ? 1 : -1)

            this.ranking.hits.reverse()

            this.ranking.hits.slice(2)

            console.log(this.ranking.hits)


            
        },

        emailMeData: {},
        emailMeDataError: {},
        emailMeFormMessage: '',
        emailMeFormLoading: false,
        emailMeFormButton: 'Send Email',

        emailMe(physician_id) {
            this.emailMeData.physician_id = physician_id
            this.emailMeFormMessage = '';
            this.emailMeFormLoading = true;
            this.emailMeFormButton = 'Sending Email...';
            fetch('/data/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.emailMeData),
            })
            .then(response => response.json())
            .then((data) => {

                if(data.status === 'error' ) {
                    this.emailMeDataError = data.data
                }
                if(data.status === 'ok' ) {
                    this.emailMeFormLoading = false
                    this.emailMeFormButton = 'Send Email'
                    this.emailMeFormMessage = 'Email has been sent'
                    this.open = false
                }
            })
        },

        // NEW ACTION FLOW

        // Subscribe Not Logged In

        actionFlowsubscriberData: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
        },

        actionFlowsubscriberFormLoading: false,
        actionFlowsubscriberButtonText: 'Start Free Doctor Tracking',
        actionFlowsubscriberErrorEmail: '',

        actionFlowSubscribeNotLoggedIn(e) {

            this.actionFlowsubscriberErrorEmail = ''

            var password = Math.random().toString(36).slice(-8);
            var physicianId = e.target.dataset.id

            this.actionFlowsubscriberData.first_name = 'Anonymous'
            this.actionFlowsubscriberData.last_name = 'Patient'
            this.actionFlowsubscriberData.password = password
            this.actionFlowsubscriberData.password_confirmation = password

            this.actionFlowsubscriberFormLoading = true;
            this.actionFlowsubscriberButtonText = 'Starting Track...';

            fetch('/data/subscribe/'+ physicianId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.actionFlowsubscriberData),
            })
            .then(response => response.json())
            .then((data) => {
                if(data.status === 'error' ) {
                    this.actionFlowsubscriberErrorEmail = data.data.email
                }
                if(data.status === 'ok' ) {
                    this.step = 2
                }
            })
            .finally(() => {
                this.actionFlowsubscriberFormLoading = false;
                this.actionFlowsubscriberButtonText = 'Start Free Doctor Tracking';
            });

        },

         // Subscribe Logged In

        actionFlowSubscribeLoggedIn(e) {

            var physicianId = e.target.dataset.id

            fetch('/patients/subscriptions/data/' + physicianId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
            })
            .then(response => response.json())
            .then((data) => {
                if(data.status = 'ok') {
                    this.open = true
                    this.step = 2
                }
            })

        },

        // Leave a Review Logged In

        actionFlowRating: 0,
        actionFlowHoverRating: 0,
        actionFlowRatings: [{'amount': 1, 'label':'Terrible'}, {'amount': 2, 'label':'Bad'}, {'amount': 3, 'label':'Okay'}, {'amount': 4, 'label':'Good'}, {'amount': 5, 'label':'Great'}],
        
        actionFlowRate(amount) {
            this.actionFlowRating = amount;
            this.actionFlowReviewData.rating =  this.actionFlowRating;
        },

        actionFlowReviewData: {
            rating: '',
            text: '',
            parent_id: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
        },

        actionFlowReviewDataError: {
            rating: '',
            text: '',
            parent_id: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            physician_id: '',
        },

        actionFlowReviewFormLoading: false,
        actionFlowReviewButtonText: 'Leave a Review',

        actionFlowSubmitReview(physicianId) {
            this.actionFlowReviewFormLoading = true;
            this.actionFlowReviewButtonText = 'Leaving Review...';
            fetch('/data/reviews/'+ physicianId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.actionFlowReviewData),
            })
            .then(response => response.json())
            .then((data) => {

                if(data.status === 'error' ) {
                    this.actionFlowReviewDataError.rating = data.data.rating
                    this.actionFlowReviewDataError.text = data.data.text
                    this.actionFlowReviewDataError.first_name = data.data.first_name
                    this.actionFlowReviewDataError.last_name = data.data.last_name
                    this.actionFlowReviewDataError.email = data.data.email
                    this.actionFlowReviewDataError.password = data.data.password
                    this.actionFlowReviewDataError.password_confirmation = data.data.password_confirmation
                    this.actionFlowReviewDataError.physician_id = data.data.physician_id
                }
                if(data.status === 'ok' ) {
                    
                    this.step = 3

                }
            })
            .finally(() => {
                this.actionFlowReviewFormLoading = false;
                this.actionFlowReviewButtonText = 'Leave a Review';
            });
        },

        actionFlowSubmitReviewGuest(physicianId) {

            this.actionFlowReviewFormLoading = true;
            this.actionFlowReviewButtonText = 'Leaving Review...';

            this.actionFlowsubscriberErrorEmail = ''

            var password = Math.random().toString(36).slice(-8);

            this.actionFlowsubscriberData.first_name = 'Anonymous'
            this.actionFlowsubscriberData.last_name = 'Patient'
            this.actionFlowsubscriberData.password = password
            this.actionFlowsubscriberData.password_confirmation = password

            fetch('/data/subscribe/'+ physicianId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.actionFlowsubscriberData),
            })
            .then(response => response.json())
            .then((data) => {
                if(data.status === 'error' ) {
                    this.actionFlowsubscriberErrorEmail = data.data.email
                }
                if(data.status === 'ok' ) {

                    fetch('/data/reviews/'+ physicianId, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                        },
                        body: JSON.stringify(this.actionFlowReviewData),
                    })
                    .then(response => response.json())
                    .then((data) => {
        
                        if(data.status === 'error' ) {
                            this.actionFlowReviewDataError.rating = data.data.rating
                            this.actionFlowReviewDataError.text = data.data.text
                            this.actionFlowReviewDataError.first_name = data.data.first_name
                            this.actionFlowReviewDataError.last_name = data.data.last_name
                            this.actionFlowReviewDataError.email = data.data.email
                            this.actionFlowReviewDataError.password = data.data.password
                            this.actionFlowReviewDataError.password_confirmation = data.data.password_confirmation
                            this.actionFlowReviewDataError.physician_id = data.data.physician_id
                        }
                        if(data.status === 'ok' ) {
                            
                            this.step = 3
        
                        }
                    })

                }
            })
            .finally(() => {
                this.actionFlowReviewFormLoading = false;
                this.actionFlowReviewButtonText = 'Leave a Review';
            });
        },

        // Subscribe Multiple

        async actionFlowSendRequest(id) {
            return new Promise(resolve => {
              setTimeout(() => {
                fetch('/patients/subscriptions/data/' + id, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                    },
                })
                .then(response => response.json())
                .then((data) => {
                    if(data.status = 'ok') {}
                })
                resolve();
              }, 1000);
            });
        },

        async actionFlowSubscribeMultiple(e) {

            var physicianIds = e.target.dataset.physician

            const physicianIdsArray = physicianIds.split(',')

            physicianIdsArray.splice(-1)

            for (const id of physicianIdsArray) {
                await this.actionFlowSendRequest(id)
            }

            window.dataLayer.push({
                'event': 'send_doctors_list'
            });

            setTimeout(() => {
                window.location.replace('/patients-pricing');
            }, 1000);

        },

        init() {

            const client = new window.MeiliSearch(meilisearchConfig)

            this.index = client.index(index)

            /*if (SEARCH_MUTEX == false) {
                this.retrieveData()
                SEARCH_MUTEX = true
            }*/

        },
    };
}
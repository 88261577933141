export default function (meilisearchConfig, locationIndex, searchOptions) {
    const defaultSearchOptions = {
        limit: 10,
        filter: 'entity = "physician"',
        sort: ['avg_rating:desc']
    }

    searchOptions = { ...defaultSearchOptions, ...searchOptions}

    const status = document.querySelector('#status');

    var lat = ''
    var lng = ''

    var SEARCH_MUTEX = false

    return {
        location: '',
        locationIndex: null,
        locationResults: '',
        
        geoFindMe() {

            if(!navigator.geolocation) {
                status.textContent = 'Geolocation is not supported by your browser';
            }
            else {
                status.textContent = 'Locating…'

                window.navigator.geolocation.getCurrentPosition( (position) => {

                        lat =  position.coords.latitude
                        lng = position.coords.longitude

                        searchOptions.sort = [
                            '_geoPoint('+ lat +', '+ lng +'):asc',
                            'avg_rating:desc'
                        ]

                        this.searchGeo(this.location)
                        
                        status.textContent = ''

                    }, () => {
                        status.textContent = 'Unable to retrieve your location'
                });
            }
        },

        async searchLocation($event) {

            $event.preventDefault()

            var city = $event.currentTarget.getAttribute('city')
            var state = $event.currentTarget.getAttribute('state')
            var zip = $event.currentTarget.getAttribute('zip')

            this.location = city + ' ' + state + ' ' + zip

            searchOptions.sort = [
                'avg_rating:desc'
            ]

            this.locationResults = await this.locationIndex.search(this.location, searchOptions)

            this.query = ''
            this.location = ''
        },

        async searchGeo(location) {
            this.locationResults = await this.locationIndex.search(this.location, searchOptions)
        },

        async init() {

            const client = new window.MeiliSearch(meilisearchConfig)

            this.locationIndex = client.index(locationIndex)

            if (SEARCH_MUTEX == false) {

                this.locationResults = await this.locationIndex.search(this.location, searchOptions)

                SEARCH_MUTEX = true
                
            }
        },
    };
}
export default function (meilisearchConfig, index, searchOptions, id, prefix) {

    function getID(str) {
        return str.split('-')[1];
    }

    var specid = ''
    specid = getID(id)

    const defaultSearchOptions = {
        hitsPerPage: 10,
        filter: 'entity = "physician" AND (specialty_id IN [' + specid + '] OR specialty_id_opt IN [' + specid + '])'
    }

    searchOptions = { ...defaultSearchOptions, ...searchOptions}

    const status = document.querySelector('#status');

    var city = ''
    var state = ''
    var lat = ''
    var lng = ''

    var SEARCH_MUTEX = false

    return {
        location: "",
        avg_rating: "",
        gender: "",
        sort: "",
        spcIndex: null,
        spcResults: '',
        specialtyId: specid,

        async filterLocation($event) {

            $event.preventDefault()

            city = $event.currentTarget.getAttribute('city')
            state = $event.currentTarget.getAttribute('state')

            this.location = city + ' ' + state
            this.query = ''
        },

        geoFindMe() {

            if(!navigator.geolocation) {
                status.textContent = 'Geolocation is not supported by your browser';
            }
            else {
                status.textContent = 'Locating…'

                window.navigator.geolocation.getCurrentPosition( (position) => {

                        lat =  position.coords.latitude
                        lng = position.coords.longitude

                        this.location = '10 miles'
                        
                        status.textContent = ''

                    }, () => {
                        status.textContent = 'Unable to retrieve your location'
                });
            }
        },

        geoSort() {

            if(!navigator.geolocation) {
                status.textContent = 'Geolocation is not supported by your browser';
            }
            else {
                status.textContent = 'Locating…'

                window.navigator.geolocation.getCurrentPosition( (position) => {

                        lat =  position.coords.latitude
                        lng = position.coords.longitude

                        searchOptions.sort = [
                            '_geoPoint('+ lat +', '+ lng +'):asc'
                        ]
                        
                        status.textContent = ''

                    }, () => {
                        status.textContent = 'Unable to retrieve your location'
                });
            }
        },

        $watchMany(fields) {
            fields.forEach((field) => {
                this.$watch(field, (val) => {
                    if (field === 'location') {
                        if (val === '') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?\(([a-zA-Z0-9_ ="]+)\)/g, '')
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?_geoRadius\(([^)]+)\)/g, '')
                        }
                        else {
                            if(~searchOptions.filter.indexOf('city')) {
                                searchOptions.filter = searchOptions.filter.replace(/( AND )?_geoRadius\(([^)]+)\)/g, '')
                                searchOptions.filter = searchOptions.filter.replace(/\(([a-zA-Z0-9_ ="]+)\)/g, '(city = "' + city + '" AND state = "' + state + '")')
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + '(city = "' + city + '" AND state = "' + state + '")'
                            }
                        }
                        if (val === '10 miles') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?\(([a-zA-Z0-9_ ="]+)\)/g, '')

                            if(~searchOptions.filter.indexOf('geoRadius')) {
                                searchOptions.filter = searchOptions.filter.replace(/( AND )?_geoRadius\(([^)]+)\)/g, ' AND _geoRadius(' + lat + ', ' + lng + ', 16000)')
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + '_geoRadius(' + lat + ', ' + lng + ', 16000)'
                            }
                        }
                    }
                    if (field === 'avg_rating') {
                        if (val === '' || val === 'all') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?avg_rating >= \d/g, '')
                        }
                        else {
                            if(~searchOptions.filter.indexOf('avg_rating')) {
                                    searchOptions.filter = searchOptions.filter.replace(/avg_rating >= \d/g, 'avg_rating >= ' + val)
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + 'avg_rating >= ' + val
                            }
                        }
                    }
                    if (field === 'gender') {
                        if (val === '' || val === 'all') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?gender = "+([MFmf])+"/g, '')
                        }
                        else {
                            if(~searchOptions.filter.indexOf('gender')) {
                                searchOptions.filter = searchOptions.filter.replace(/gender = "+([MFmf])+"/g, 'gender = "' + val + '"')
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + 'gender = "' + val + '"'
                            }
                        }
                    }
                    if (field === 'sort') {
                        if (val === '' || val === 'bestmatches') {
                            searchOptions.sort = null
                        }
                        if (val === 'mostrated') {
                            searchOptions.sort = [
                                'total_reviews:desc'
                            ]
                        }
                    }

                    this.search(this.specialty)
                });
            });
        },

        async search (specialty) {
            this.spcResults = await this.spcIndex.search(this.specialty, searchOptions)
        },

        init() {
            const client = new window.MeiliSearch(meilisearchConfig)

            this.spcIndex = client.index(index)

            if (SEARCH_MUTEX == false) {

                this.search(this.specialty)

                this.$watchMany(['location', 'gender', 'avg_rating', 'sort' ])
                SEARCH_MUTEX = true
            }
        },
    };
}
export default function (meilisearchConfig, index, searchOptions, page) {
    const defaultSearchOptions = {
        hitsPerPage: 10,
        filter: 'entity = "physician"'
    }

    searchOptions = { ...defaultSearchOptions, ...searchOptions}

    const status = document.querySelector('#status');

    var city = ''
    var state = ''
    var specid = ''
    var specname = ''
    var lat = ''
    var lng = ''

    var SEARCH_MUTEX = false

    return {
        queryadvanced: Alpine.$persist(''),
        location: Alpine.$persist(''),
        specialties: Alpine.$persist(''),
        avg_rating: Alpine.$persist(''),
        gender: Alpine.$persist(''),
        sort: Alpine.$persist(''),
        advancedIndex: null,
        advancedResults: '',

        searchFilter: Alpine.$persist(''),
        searchSort: Alpine.$persist(null),

        async filterLocation($event) {

            $event.preventDefault()

            city = $event.currentTarget.getAttribute('city')
            state = $event.currentTarget.getAttribute('state')

            this.location = city + ' ' + state
            this.query = ''
        },

        async filterSpeciality($event) {

            $event.preventDefault()

            specid = $event.currentTarget.getAttribute('specid')
            specname = $event.currentTarget.getAttribute('specname')

            this.specialties = specname
            this.query = ''
        },

        geoFindMe() {

            if(!navigator.geolocation) {
                status.textContent = 'Geolocation is not supported by your browser';
            }
            else {
                status.textContent = 'Locating…'

                window.navigator.geolocation.getCurrentPosition( (position) => {

                        lat =  position.coords.latitude
                        lng = position.coords.longitude

                        this.location = '10 miles'
                        
                        status.textContent = ''

                    }, () => {
                        status.textContent = 'Unable to retrieve your location'
                });
            }
        },

        geoSort() {

            if(!navigator.geolocation) {
                status.textContent = 'Geolocation is not supported by your browser';
            }
            else {
                status.textContent = 'Locating…'

                window.navigator.geolocation.getCurrentPosition( (position) => {

                        lat =  position.coords.latitude
                        lng = position.coords.longitude

                        searchOptions.sort = [
                            '_geoPoint('+ lat +', '+ lng +'):asc'
                        ]
                        
                        status.textContent = ''

                    }, () => {
                        status.textContent = 'Unable to retrieve your location'
                });
            }
        },

        $watchMany(fields) {
            fields.forEach((field) => {
                this.$watch(field, (val) => {
                    if (field === 'location') {
                        if (val === '') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?\(([a-zA-Z0-9_ ="]+)\)/g, '')
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?_geoRadius\(([^)]+)\)/g, '')
                        }
                        else {
                            if(~searchOptions.filter.indexOf('city')) {
                                searchOptions.filter = searchOptions.filter.replace(/( AND )?_geoRadius\(([^)]+)\)/g, '')
                                searchOptions.filter = searchOptions.filter.replace(/\(([a-zA-Z0-9_ ="]+)\)/g, '(city = "' + city + '" AND state = "' + state + '")')
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + '(city = "' + city + '" AND state = "' + state + '")'
                            }
                        }
                        if (val === '10 miles') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?\(([a-zA-Z0-9_ ="]+)\)/g, '')

                            if(~searchOptions.filter.indexOf('geoRadius')) {
                                searchOptions.filter = searchOptions.filter.replace(/( AND )?_geoRadius\(([^)]+)\)/g, ' AND _geoRadius(' + lat + ', ' + lng + ', 16000)')
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + '_geoRadius(' + lat + ', ' + lng + ', 16000)'
                            }
                        }
                    }
                    if (field === 'specialties') {
                        if (val === '') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?\(specialty_id IN \[(.*?)\] OR specialty_id_opt IN \[(.*?)\]\)/g, '')
                        }
                        else {
                            if(~searchOptions.filter.indexOf('specialty_id')) {
                                    searchOptions.filter = searchOptions.filter.replace(/\(specialty_id IN \[(.*?)\] OR specialty_id_opt IN \[(.*?)\]\)/g, '(specialty_id IN [' + specid + '] OR specialty_id_opt IN [' + specid + '])')
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + '(specialty_id IN [' + specid + '] OR specialty_id_opt IN [' + specid + '])'
                            }
                        }
                    }
                    if (field === 'avg_rating') {
                        if (val === '' || val === 'all') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?avg_rating <= \d/g, '')
                        }
                        else {
                            if(~searchOptions.filter.indexOf('avg_rating')) {
                                    searchOptions.filter = searchOptions.filter.replace(/avg_rating <= \d/g, 'avg_rating <= ' + val)
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + 'avg_rating <= ' + val
                            }
                        }
                    }
                    if (field === 'gender') {
                        if (val === '' || val === 'all') {
                            searchOptions.filter = searchOptions.filter.replace(/( AND )?gender = "+([MFmf])+"/g, '')
                        }
                        else {
                            if(~searchOptions.filter.indexOf('gender')) {
                                searchOptions.filter = searchOptions.filter.replace(/gender = "+([MFmf])+"/g, 'gender = "' + val + '"')
                            }
                            else {
                                searchOptions.filter = searchOptions.filter + ' AND '
                                searchOptions.filter = searchOptions.filter + 'gender = "' + val + '"'
                            }
                        }
                    }
                    if (field === 'sort') {
                        if (val === '' || val === 'bestmatches') {
                            searchOptions.sort = null
                        }
                        if (val === 'mostrated') {
                            searchOptions.sort = [
                                'total_reviews:desc'
                            ]
                        }
                    }

                    this.searchFilter = searchOptions.filter
                    
                    if(searchOptions.sort) {
                        this.searchSort = searchOptions.sort
                    }
                    else {
                        this.searchSort = null
                    }

                    this.search(this.queryadvanced)
                });
            });

        },

        async search (queryadvanced) {

            if(this.searchFilter) {
                searchOptions.filter = this.searchFilter
                searchOptions.sort = this.searchSort
            }

            this.advancedResults = await this.advancedIndex.search(this.queryadvanced, searchOptions)
        },

        init() {
            const client = new window.MeiliSearch(meilisearchConfig)

            this.advancedIndex = client.index(index)

            if (SEARCH_MUTEX == false) {

                this.search(this.queryadvanced)

                this.$watchMany(['queryadvanced', 'location', 'specialties', 'gender', 'avg_rating', 'sort' ])
                
                SEARCH_MUTEX = true

            }
        },
    };
}
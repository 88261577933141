import Cookies from 'js-cookie'

export default function (reviewId) {

    var REVIEW_MUTEX = false

    return {

        review: {},

        data: {},

        phyisicanReply: {
            text: ''
        },

        reviewLoading: false,

        async retrieveData() {

            this.reviewLoading = true

            await fetch('/share/' + reviewId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
            })
            .then(response => response.json())
            .then((data) => {
                if(data.status = 'ok') {

                    this.review = data.data

                    this.data = this.review.physician
    
                    this.review.reviewed_at = new Date(this.review.reviewed_at)
        
                    this.review.reviewed_at = this.review.reviewed_at.toLocaleDateString("en-US")

                    this.reviewLoading = false
                }
            })
        },

        async like(e) {

            var reviewId = e.target.dataset.id
            var likes = e.target.dataset.likes

            let like = Cookies.get('liked' + reviewId )

            if (like !== 'true') {
                await fetch('/data/like/' + reviewId, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                    },
                })
                .then(response => response.json())
                .then((data) => {
                    if (data.status === 'ok') {

                        Cookies.set('liked'+ reviewId, true, { expires: 365, secure: true })

                        if(likes) {
                            likes = parseInt(likes) + 1
                        }
                        else {
                            likes = 1
                        }
                        e.target.outerHTML = '<p class="profile-like review-action mb-0"><span>' + likes + ' Likes</span></p>'
                        
                    }
                })
            }
        },

        checkCookie(cname) {
            let like = Cookies.get(cname);
            if (like === 'true') {
                return true
            } else {
                return false
            }
        },

        addReply(e) {

            var reviewId = e.target.dataset.id

            fetch('/physicians/reviews/replies/' + reviewId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.phyisicanReply),
            })

        },

        init() {

            if (REVIEW_MUTEX == false) {
                this.retrieveData()
                REVIEW_MUTEX = true
            }

        },
    }
}
export default function () {

    return {

        catchDataStatus: false,
        originalData: {},

        catchData(data) {
            if(this.catchDataStatus === false) {
                this.originalData = JSON.parse(JSON.stringify(data))
                this.catchDataStatus = true
                this.updateStatus = false
            }
        },

        updateStatus: false,
        saveChangesPopup: false,

        checkChanges(updatedData) {

            document.addEventListener('mouseout', e => {

                if (!e.toElement && !e.relatedTarget) {

                    if(((JSON.stringify(this.originalData) === JSON.stringify(updatedData)) === false) && (this.updateStatus === false)) {
                        this.saveChangesPopup = true
                    }
                    
                }

            })
        },

        specialtiesClassified: '',

        async specialtiesMap() {
            await fetch('/specialties_classified.json', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
            })
            .then(response => response.json())
            .then((data) => {
                this.specialtiesClassified = data
            })
        },

        watchSpecialties () {
            this.$watch("specialtiesQuery", (query) => {
                if (query === '') {
                    this.specialtiesResults = ''
                    return
                }
                this.getSpecialties(query)
            });
        },

        async getSpecialties (query) {
            await fetch('/data/specialties-classified?q=' + query, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
            })
            .then(response => response.json())
            .then((data) => {
                if(data.status === 'ok') {
                    this.specialtiesResults = data.data
                }
            })
        },

        pages: [],
        dotsLeft: false,
        dotsRight: false,

        pagination(currentPage, totalpages, endsCount, middleCount) {

            endsCount = 1
            middleCount = 2

            for (let i = 1; i <= totalpages; i++) {
                if(i === currentPage) {
                    this.pages.push(i)
                }
                else {
                    if( i < endsCount || (currentPage && i >= currentPage - middleCount && i <= currentPage + middleCount) ) {
                        this.pages.push(i)
                    }
                    if( currentPage - middleCount*2 >= 1) {
                        this.dotsLeft = true
                    }
                    if( currentPage <= totalpages - middleCount*2) {
                        this.dotsRight = true
                    }
                }
            }

        },
    }

}
export default function () {
  
    const FORM_URL = '/profile';

    return {
        subscribeData: {
          email: '',
        },
        subscribeMessage: '',
        formLoading: false,
        buttonText: 'Subscribe',
        submitForm() {
            this.subscribeMessage = '';
            this.formLoading = true;
            this.buttonText = 'Subscribing...';
            fetch(FORM_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
                },
                body: JSON.stringify(this.subscribeData),
            })
                .then(() => {
                    this.subscribeData.email = '';
                    this.subscribeMessage = 'Form successfully submitted.';
                })
                .catch(() => {
                    this.subscribeMessage = 'Something went wrong.';
                })
                .finally(() => {
                    this.formLoading = false;
                    this.buttonText = 'Subscribe';
                });
        },
    };
}